// Vendor Imports
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

// Project Imports
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '../.... Remove this comment to see the full error message
import { setMapInfoDismissed } from '../../actions';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '../.... Remove this comment to see the full error message
import { hasVisualizationType, hasVisualizationDimension, hasSelectedTableVisualizationColumns, isTable } from '../../selectors/vifAuthoring';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '../V... Remove this comment to see the full error message
import VisualizationPreview from '../VisualizationPreview/index';
import I18n from 'common/i18n';

// TODO: replace tempVifAuthoring when an official VifAuthoring interface is created
interface tempVifAuthoring {
  vifs: any[],
  authoring: {
    selectedVisualizationType: string,
    mapInfoDismissed: boolean
  }
}

export interface VisualizationPreviewContainerProps {
  onSetMapInfoDismissed: () => void;
  vifAuthoring: tempVifAuthoring;
}

export const VisualizationPreviewContainer: React.FC<VisualizationPreviewContainerProps> = (props: VisualizationPreviewContainerProps) => {

  const { vifAuthoring } = props;

  const hasType = hasVisualizationType(vifAuthoring),
        hasDimension = hasVisualizationDimension(vifAuthoring),
        hasSelectedTableColumns = hasSelectedTableVisualizationColumns(vifAuthoring);

  const noColumnsMessageTemplate = (): React.ReactElement => {
    return (
      <div className="visualization-preview-info-container">
        <div className="visualization-preview-info-content">
          <p className="visualization-preview-info-description center italicize">{I18n.t('shared.visualizations.preview.no_selected_columns.message')}</p>
        </div>
      </div>
    );
  };

  const gettingStartedMessageTemplate = () => {
    return (
      <div className="visualization-preview-info-container">
        <div  className="visualization-preview-info-content">
          <div className="visualization-preview-info-title">{I18n.t('shared.visualizations.preview.get_started.title')}</div>
          <p className="visualization-preview-info-description">{I18n.t('shared.visualizations.preview.get_started.description')}</p>
        </div>
      </div>
    );
  };

  const shouldRenderNoColumnsMessage = ((isTableViz: boolean, hasTableColumns: boolean): boolean => {
    // For a table viz, if there are no selected columns, display no_selected_columns.message
    // otherwise display null
    if (isTableViz && !hasTableColumns) {
      return true;
    }

    return false;
  });

  const renderNoColumnsMessage = shouldRenderNoColumnsMessage(isTable(vifAuthoring), hasSelectedTableColumns),
        renderGettingStartedMessage = !isTable(vifAuthoring) && !(hasType && hasDimension);

  const previewContainerClasses = classnames({
    'visualization-preview-container': true,
    'socrata-table-visualization-preview': isTable(vifAuthoring)
  });

  return (
    <div className={previewContainerClasses}>
      {(renderNoColumnsMessage) ? noColumnsMessageTemplate() : null}
      {(renderGettingStartedMessage) ? gettingStartedMessageTemplate() : null}
      <VisualizationPreview hide={renderNoColumnsMessage || renderGettingStartedMessage}/>
    </div>
   );
};

const mapDispatchToProps = {
  onSetMapInfoDismissed: setMapInfoDismissed
};

const mapStateToProps = (state: any) => {
  return _.pick(state, ['vifAuthoring']);
};

export default connect(mapStateToProps, mapDispatchToProps)(VisualizationPreviewContainer);
